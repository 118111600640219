import Vue from 'vue'
import Router from 'vue-router'
import VueBodyClass from 'vue-body-class';

import Login from '@/components/account/Login'
import Error from '@/components/account/Error'

import Home from '@/components/_common/Home'
import SutterSelect from '@/components/other/SutterSelect'

import ResetPassword from '@/components/account/ResetPassword'

import Register1 from '@/components/account/registration/Register1'
import Register2 from '@/components/account/registration/Register2'
import Register3 from '@/components/account/registration/Register3'
import Register4 from '@/components/account/registration/Register4'
import Register5 from '@/components/account/registration/Register5'

import AccountUpdate1 from '@/components/account/update/AccountUpdate1'
import AccountUpdate2 from '@/components/account/update/AccountUpdate2'
import AccountUpdate3 from '@/components/account/update/AccountUpdate3'
import AccountUpdate4 from '@/components/account/update/AccountUpdate4'

import ForgotPassword1 from '@/components/account/forgotpassword/ForgotPassword1'
import ForgotPassword2 from '@/components/account/forgotpassword/ForgotPassword2'
import ForgotPassword3 from '@/components/account/forgotpassword/ForgotPassword3'
import ForgotPassword4 from '@/components/account/forgotpassword/ForgotPassword4'
import ForgotPassword5 from '@/components/account/forgotpassword/ForgotPassword5'
import ForgotPassword6 from '@/components/account/forgotpassword/ForgotPassword6'
import ForgotPassword7 from '@/components/account/forgotpassword/ForgotPassword7'
import ForgotPassword8 from '@/components/account/forgotpassword/ForgotPassword8'
import ForgotPassword9 from '@/components/account/forgotpassword/ForgotPassword9'

import UnlockAccount1 from '@/components/account/unlock/UnlockAccount1'
import UnlockAccount2 from '@/components/account/unlock/UnlockAccount2'
import UnlockAccount3 from '@/components/account/unlock/UnlockAccount3'
import UnlockAccount4 from '@/components/account/unlock/UnlockAccount4'

import AdminView from '@/components/admin/AdminView'
import Accounts from '@/components/admin/Accounts'
import AccountsManage from '@/components/admin/AccountsManage'
import AdminLogin from '@/components/admin/AdminLogin'
import Overview from '@/components/admin/Overview'

import BenefitsLookup from '@/components/main/BenefitsLookup'
import SubmitAuthorizations from '@/components/main/SubmitAuthorizations'
import ViewClaims from '@/components/main/ViewClaims'
import YourProvidersView from '@/components/main/YourProvidersView'
import Resources from '@/components/main/Resources'
import Contact from '@/components/main/Contact'

import Settings from '@/components/other/Settings'
import Announcements from '@/components/other/Announcements';

import Print from '@/components/main/submit-auth/Print';

import store from '../store'
import trackingService from '../services/api/tracking'
import ManageAuthorizations from '../components/main/manage-auth/ManageAuthorizations.vue';

Vue.use(Router);

// ~~~~~~~~
//IMPORTANT! If you're changing/adding routes, make sure you check trackRouteClick() in this file to see if anything there needs updated as well.
//			 Not checking for that could potentially break some click tracking.  
// ~~~~~~~~
const routes = [

	// ~~~~~~~~~~~~~
	// Login / Registration
	// ~~~~~~~~~~~~~
	{
		path: '/',
		name: 'Login',
		component: Login,
		meta: { 
			hideLayout: true,
			canImpersonate: true,
			bodyClass: 'body-full-background', 
			title: 'Login' 
		}
	},
	{
		path: '/Error',
		name: 'Error',
		component: Error,
		meta: { 
			hideLayout: true,
			canImpersonate: true,
			bodyClass: 'body-full-background', 
			title: 'Error' 
		}
	},
    {
		path: '/register-step-1',
		name: 'Register1',
		component: Register1,
		meta: { 
			hideLayout: true,
			bodyClass: 'body-full-background', 
			title: 'Register'  
		}
	},
	{
		path: '/register-step-2',
		name: 'Register2',
		component: Register2,
		meta: { 
			hideLayout: true,
			bodyClass: 'body-full-background', 
			title: 'Register'  
		}
	},
	{
		path: '/register-step-3',
		name: 'Register3',
		component: Register3,
		meta: { 
			hideLayout: true,
			bodyClass: 'body-full-background', 
			title: 'Register'  
		}
	},
	{
		path: '/register-step-4',
		name: 'Register4',
		component: Register4,
		meta: { 
			hideLayout: true,
			bodyClass: 'body-full-background', 
			title: 'Register'  
		}
	},
	{
		path: '/register-step-5',
		name: 'Register5',
		component: Register5,
		meta: { 
			hideLayout: true,
			bodyClass: 'body-full-background', 
			title: 'Register'  
		}
	},
	// ~~~~~~~~~~~~~
	// Reset Password
	// ~~~~~~~~~~~~~
	{
		path: '/reset-password',
		name: 'ResetPassword',
		component: ResetPassword,
		meta: { 
			bodyClass: 'body-full-background', 
			title: 'Reset Password',
			requiresAuth: true,
			limited: true,
			hideLayout: true
		}
	},
	// ~~~~~~~~~~~~~
	// Account Update
	// ~~~~~~~~~~~~~
	{
		path: '/account-update-step-1',
		name: 'AccountUpdate1',
		component: AccountUpdate1,
		meta: { 
			bodyClass: 'body-full-background', 
			title: 'Account Update',
			hideLayout: true
		}
	},
	{
		path: '/account-update-step-2',
		name: 'AccountUpdate2',
		component: AccountUpdate2,
		meta: { 
			bodyClass: 'body-full-background', 
			title: 'Account Update',
			hideLayout: true
		}
	},
	{
		path: '/account-update-step-3',
		name: 'AccountUpdate3',
		component: AccountUpdate3,
		meta: { 
			bodyClass: 'body-full-background', 
			title: 'Account Update',
			hideLayout: true
		}
	},
	{
		path: '/account-update-step-4',
		name: 'AccountUpdate4',
		component: AccountUpdate4,
		meta: { 
			bodyClass: 'body-full-background', 
			title: 'Account Update',
			hideLayout: true
		}
	},

	// ~~~~~~~~~~~~~
	// Forgot Password
	// ~~~~~~~~~~~~~
	{
		path: '/forgot-password-step-1',
		name: 'ForgotPassword1',
		component: ForgotPassword1,
		meta: { 
			bodyClass: 'body-full-background', 
			title: 'Forgot Password',
			hideLayout: true
		}
	},
	{
		path: '/forgot-password-step-2',
		name: 'ForgotPassword2',
		component: ForgotPassword2,
		meta: { 
			bodyClass: 'body-full-background', 
			title: 'Forgot Password',
			hideLayout: true
		}
	},
	{
		path: '/forgot-password-step-3',
		name: 'ForgotPassword3',
		component: ForgotPassword3,
		meta: { 
			bodyClass: 'body-full-background', 
			title: 'Forgot Password',
			hideLayout: true
		}
	},
	{
		path: '/forgot-password-step-4',
		name: 'ForgotPassword4',
		component: ForgotPassword4,
		meta: { 
			bodyClass: 'body-full-background', 
			title: 'Forgot Password',
			hideLayout: true
		}
	},
	{
		path: '/forgot-password-step-5',
		name: 'ForgotPassword5',
		component: ForgotPassword5,
		meta: { 
			bodyClass: 'body-full-background', 
			title: 'Forgot Password',
			hideLayout: true
		}
	},
	{
		path: '/forgot-password-step-6',
		name: 'ForgotPassword6',
		component: ForgotPassword6,
		meta: { 
			bodyClass: 'body-full-background', 
			title: 'Forgot Password',
			hideLayout: true
		}
	},
	{
		path: '/forgot-password-step-7',
		name: 'ForgotPassword7',
		component: ForgotPassword7,
		meta: { 
			bodyClass: 'body-full-background', 
			title: 'Forgot Password',
			hideLayout: true
		}
	},
	{
		path: '/forgot-password-step-8',
		name: 'ForgotPassword8',
		component: ForgotPassword8,
		meta: { 
			bodyClass: 'body-full-background', 
			title: 'Forgot Password',
			hideLayout: true
		}
	},
	{
		path: '/forgot-password-step-9',
		name: 'ForgotPassword9',
		component: ForgotPassword9,
		meta: { 
			bodyClass: 'body-full-background', 
			title: 'Forgot Password',
			hideLayout: true
		}
	},

	// ~~~~~~~~~~~~~
	// Unlock Account
	// ~~~~~~~~~~~~~
	{
		path: '/unlock-account-step-1',
		name: 'UnlockAccount1',
		component: UnlockAccount1,
		meta: { 
			bodyClass: 'body-full-background', 
			title: 'Unlock Your Account',
			hideLayout: true
		}
	},
	{
		path: '/unlock-account-step-2',
		name: 'UnlockAccount2',
		component: UnlockAccount2,
		meta: { 
			bodyClass: 'body-full-background', 
			title: 'Unlock Your Account',
			hideLayout: true
		}
	},
	{
		path: '/unlock-account-step-3',
		name: 'UnlockAccount3',
		component: UnlockAccount3,
		meta: { 
			bodyClass: 'body-full-background', 
			title: 'Unlock Your Account',
			hideLayout: true
		}
	},
	{
		path: '/unlock-account-step-4',
		name: 'UnlockAccount4',
		component: UnlockAccount4,
		meta: { 
			bodyClass: 'body-full-background', 
			title: 'Unlock Your Account',
			hideLayout: true
		}
	},

	// ~~~~~~~~~~~~~
	// Admin Pages
	// ~~~~~~~~~~~~~
	{
		path: '/login-admin',
		name: 'LoginAdmin',
		component: AdminLogin,
		meta: { 
			bodyClass: 'body-full-background',
			title: 'Admin Login',
			canImpersonate: true
		}
	},
	{
		path: '/admin',
		name: '/admin',
		component: AdminView,
		meta: { 
			bodyClass: 'body-no-background',
			requiresAuth: true,
			menu: true,
			admin: true,
			title: 'Admin'
		},
		children: [
			{
				path: '/admin/overview',
				name: 'Overview',
				component: Overview,
				meta: {
					requiresAuth: true,
					title: 'Admin'
				}
			},
			{
				path: '/admin/accounts/:type',
				name: 'Accounts',
				component: Accounts,
				meta: { 
					requiresAuth: true,
					title: 'Admin'
				},	
			},		
			{
				path: '/admin/accounts/:type/:id',
				name: 'AccountsManage',
				component: AccountsManage,
				meta: {
					requiresAuth: true,
					title: 'Admin'
				}
			}				
		]
	},
	
	// ~~~~~~~~~~~~~
	// Main Menu Pages
	// ~~~~~~~~~~~~~
	{
		path: '/home',
		name: 'Home',
		component: Home,
		meta: {
			requiresAuth: true,
			limited: true,
			menu: true,
			bodyClass: 'body-slim-background h-100', 
			title: 'Home',
			trackingActionName: 'HomeTab'
		}
	},
	{
		path: '/benefits-lookup',
		name: 'BenefitsLookup',
		component: BenefitsLookup,
		meta: {
			bodyClass: 'body-no-background',
			requiresAuth: true,
			menu: true,
			title: 'Benefits Lookup',
			trackingActionName: 'BenefitsLookupTab'
		}
	},
	{
		path: '/submit-authorizations',
		name: 'SubmitAuthorizations',
		component: SubmitAuthorizations,
		meta: {
			bodyClass: 'body-no-background',
			requiresAuth: true,
			menu: true,
			title: 'Submit Authorizations',
			trackingActionName: 'SubmitAuthorizationsTab'
		}
	},
	{
		path: '/submit-authorizations/print',
		name: 'PrintAuthorization',
		component: Print,
		meta: {
			requiresAuth: true,
			hideHeader: true,
			hideFooter: true,
			hideLayout: true,
			disableIdleTimeout: true,
			bodyClass: 'body-no-background',
			title: 'Print Authorization'
		}
	},
	{
		path: '/view-authorizations',
		name: 'ViewAuthorizations',
		component: ManageAuthorizations,
		meta: {
			bodyClass: 'body-no-background',
			requiresAuth: true,
			menu: true,
			canImpersonate: true,
			title: 'Manage Authorizations',
			trackingActionName: 'ViewAuthorizationsTab'
		}
	},
	{
		path: '/view-claims',
		name: 'ViewClaims',
		component: ViewClaims,
		meta: {
			bodyClass: 'body-no-background',
			requiresAuth: true,
			menu: true,
			canImpersonate: true,
			title: 'View Claims',
			trackingActionName: 'ViewClaimsTab'
		}
	},
	{
		path: '/your-providers',
		name: '/yourproviders',
		component: YourProvidersView,
		meta: { 
			bodyClass: 'body-no-background',
			requiresAuth: true,
			menu: true,
			title: 'Your Providers',
			trackingActionName: 'YourProvidersTab'
		},
	},
	{
		path: '/resources',
		name: 'Resources',
		component: Resources,
		meta: {
			bodyClass: 'body-no-background',
			requiresAuth: true,
			limited: true,
			menu: true,
			title: 'Resources',
			trackingActionName: 'ResourcesTab'
		}
	},
	{
		path: '/contact',
		name: 'Contact',
		component: Contact,
		meta: {
			bodyClass: 'body-no-background',
			requiresAuth: true,
			limited: true,
			menu: true,
			title: 'Contact',
			trackingActionName: 'ContactTab'
		}
	},
	// ~~~~~~~~~~~~~
	// Misc routes
	// ~~~~~~~~~~~~~
	{
		path: '/settings',
		name: 'Settings',
		component: Settings,
		meta: {
			requiresAuth: true,
			limited: true,
			bodyClass: 'body-no-background',
			title: 'Settings'
		}
	},
	{
		path: '/sutterselect',
		name: 'SutterSelect',
		component: SutterSelect,
		meta: {
			requiresAuth: true,
			limited: true,
			bodyClass: 'body-no-background',
			title: 'SutterSelect'
		}
	},
	{
		path: '/announcements',
		name: 'Announcements',
		component: Announcements,
		meta: {
			requiresAuth: true,
			limited: true,
			bodyClass: 'body-no-background',
			title: 'Announcements'
		}
	}
];

const router = new Router({routes});

const vueBodyClass = new VueBodyClass(routes);

router.beforeEach((to, from, next) => {
	const login = { name: 'Login' };
	const loginAdmin = { name: 'LoginAdmin' };
	const serverError = { name: 'Error' };
	const loginRoutes = ['Login', 'LoginAdmin'];
	const resetPasswordName = 'ResetPassword';
	const isAdminView = to.matched.find(x => x.meta.admin) ? true : false;
	const authUser = store.state.currentUser ? store.state.currentUser.user : null;

	if (to.name == "Login" && to.path == "/" && store.state.settings.data.states.length == 0) {
		next(serverError);
	} else if (to.name == "Error" && to.path == "/Error" && store.state.settings.data.states.length != 0) {
		next(Login);
	} else if (authUser && authUser.impersonatingAs && !to.meta.canImpersonate && !isAdminView) {
		next(false);
	} else if (authUser && !authUser.hasTemporaryPassword && to.name === resetPasswordName) {
		next(false);
	} else if (authUser && authUser.hasTemporaryPassword && to.name !== resetPasswordName && !loginRoutes.includes(to.name)) {
		next({ name: resetPasswordName });
	} else if (to.meta.requiresAuth === true) {
		if (authUser && authUser.token && !authUser.forceAccountUpdate) {
			if(to.path === '/admin') {
				next({path: '/admin/overview'});
			} else if (isAdminView && !authUser.isEmployee) {
				next(login);
			} else if (!to.meta.limited && authUser.isLimited) {
				next(login);
			}	else {
				vueBodyClass.guard(to, next);
			}
		} else {
			next(!authUser?.isEmployee ? login : loginAdmin);
		}
	} else {
		vueBodyClass.guard(to, next);
	}
});

function trackRouteClick(trackingActionName) {
	if (trackingActionName != null && trackingActionName != '')	trackingService.trackClick(trackingActionName);
}

const DEFAULT_TITLE = "Care Coordinators by Quantum Health";
const TITLE_SUFFIX = " | Care Coordinators by Quantum Health";
router.afterEach((to) => {
	trackRouteClick(to.meta.trackingActionName || '');

	Vue.nextTick(() => {
		document.title = to.meta && to.meta.title ? (to.meta.title + TITLE_SUFFIX) : DEFAULT_TITLE;
	});
});

export default router;