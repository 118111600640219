import axios from "axios";
import headers from "../authHeaders";

export interface ProviderSearchRequest {
  rootSearchTerm: string | null
  loginId: string | null
  state: string | null
  includeAssociations: boolean
  includeRemovedOnly: boolean
  includeFavoritesOnly: boolean
}

export const enum ProviderSearchFlags {
  none = 0,
  includeAssociations = 1 << 0,
  includeRemovedOnly = 1 << 1,
  includeFavoritesOnly = 1 << 2
}

export interface Provider {
  favorite: boolean
  taxId: string
  npi: string
  fullName: string
  address1: string
  address2: string
  city: string
  state: string
  zipCode: string
  locationId: number
  totalRows: number
  isFacility: boolean
  qhProviderId: string
}

export interface SaveAssociationFavoriteRequest {
  associationId: number
  favorite: boolean
}

export interface TempProviderInfo {
  id: number | null
  add: boolean | null
  same: boolean | null
  unknown: boolean | null
  name: string | null
  networkStatus: string | null
  phone: string | null
  phoneExt: string | null
  fax: string | null
  firstName: string | null
  lastName: string | null
  taxId: string | null
  npi: string | null
  address: string | null
  address2: string | null
  city: string | null
  state: string | null
  zip: string | null
  hospitalist: boolean | null
  missingNpi: boolean | null
  missingAddress: boolean | null
  isFacility: boolean | null
}

export default {
  async searchByNpi(npi: string, flags: number, pageSize: number = 10, pageNumber: number = 0): Promise<Provider[]> {
    const response = await axios({
      method: "get",
      url: (process.env.NODE_ENV == "production"
        ? process.env.VUE_APP_EXTERNALPROVIDERS_API
        : "externalproviders") + "/api/provider/search/"
        + `?npi=${npi}&flags=${flags}&pageSize=${pageSize}&pageNumber=${pageNumber}`,
      headers: await headers.getAuthHeaders()
    })
    return response.data
  },
  async searchByNameAndState(name: string, state: string, flags: number, pageSize: number = 10, pageNumber: number = 0): Promise<Provider[]> {
    const response = await axios({
      method: "get",
      url: (process.env.NODE_ENV == "production"
        ? process.env.VUE_APP_EXTERNALPROVIDERS_API
        : "externalproviders") + "/api/provider/search/"
        + `?name=${encodeURIComponent(name)}&state=${state}&flags=${flags}&pageSize=${pageSize}&pageNumber=${pageNumber}`,
      headers: await headers.getAuthHeaders()
    })
    return response.data
  },
  async searchForProviders(search: ProviderSearchRequest): Promise<Provider[]> {
    return axios({
      method: "post",
      url:
        (process.env.NODE_ENV == "production"
          ? process.env.VUE_APP_EXTERNALPROVIDERS_API
          : "externalproviders") + "/api/provider/search/",
      data: search,
      headers: await headers.getAuthHeaders(),
    }).then((response) => {
      return response.data;
    });
  },
  async saveAssociationFavorite(request: SaveAssociationFavoriteRequest) {
    return axios({
      method: "post",
      url:
        (process.env.NODE_ENV == "production"
          ? process.env.VUE_APP_EXTERNALPROVIDERS_API
          : "externalproviders") + "/api/provider/favorite",
      data: request,
      headers: await headers.getAuthHeaders(),
    }).then((response) => {
      return response.data;
    });
  },
  async saveProvider(request: TempProviderInfo): Promise<number> {
    return axios({
      method: "post",
      url:
        (process.env.NODE_ENV == "production"
          ? process.env.VUE_APP_EXTERNALPROVIDERS_API
          : "externalproviders") + "/api/provider/SaveTempProvider",
      data: request,
      headers: await headers.getAuthHeaders(),
    }).then((response) => {
      return response.data;
    });
  },
};
